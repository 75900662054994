import React, {Component} from 'react';
import styled from 'styled-components';
import ReactPopup from 'reactjs-popup';
import colors from '../../../styles/colors';
import CloseIcon from '../../../resources/img/close-popup.svg'
import Cookies from 'universal-cookie';
import MailchimpSubscribePopup from './MailchimpSubscribePopup';

const PopUpContent = styled.div`
    text-align: center;
`;

const Close = styled.img`
    height: 12px;
    position: absolute;
    right: 13px;
    top: 25px;
    cursor: pointer;
    @media(min-width: 768px) {
        right: 25px;
        top: 20px;
    }
`;

const contentStyle = {
  width: '567px',
  margin: 'auto',
  border: `1px solid ${colors.green}`,
  paddingTop: '46px',
  paddingLeft: '72px',
  paddingRight: '72px',
  paddingBottom: '28px',
  boxSizing: 'border-box',
  height: 'auto',
  overflow: 'auto',
  position: 'relative',
  background: `${colors.white}`,
  boxShadow: '0px 17px 21px 0px rgba(0, 0, 0, 0.11)'
};

const contentStyleMobile = {
  width: '100%',
  maxWidth: '375px',
  margin: 'auto',
  border: `1px solid ${colors.green}`,
  paddingTop: '47px',
  paddingBottom: '23px',
  paddingLeft: '23px',
  paddingRight: '23px',
  height: 'auto',
  overflow: 'auto',
  position: 'relative',
  background: `${colors.white}`,
  boxShadow: '0px 17px 21px 0px rgba(0, 0, 0, 0.11)'
};

const overlayStyle = {
  overflowY: 'auto',
  background: 'rgba(255, 255, 255, 0.9)',
};

const overlayStyleMobile = {
  overflowY: 'auto',
  background: 'rgba(255, 255, 255, 0.9)',
};

const url = "https://greenchilli.us4.list-manage.com/subscribe/post?u=6889ac4c1d10d2c8742b76703&amp;id=9597dc0a75";

class NewsletterPopup extends Component {
  state = {
    width: 0,
    height: 0,
    open: false
  };

  checkPopup() {
    const cookies = new Cookies();
    if (!cookies.get('popUpSeen')) {
      this.openModal();

      const current = new Date();
      const nextYear = new Date();

      nextYear.setFullYear(current.getFullYear() + 1);

      cookies.set('popUpSeen',true, {path: '/', expires: nextYear});
    }
  }

  openModal = () => this.setState({ open: true });

  closeModal = () => this.setState({ open: false });



  componentDidMount = () => {
    this.checkPopup();
    if (typeof window !== 'undefined') {
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
    }
  };

  componentWillUnmount = () => {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.updateWindowDimensions);
    }
  };

  updateWindowDimensions = () => {
    if (typeof window !== 'undefined') {
      this.setState({width: window.innerWidth, height: window.innerHeight});
    }
  };

  getContentStyle = () => {
    if (this.state.width >= 700) {
      return contentStyle;
    } else if (this.state.width < 700) {
      return contentStyleMobile;
    }
  };

  render() {
    const {open} = this.state;
    const {closeModal} = this;
    return (
      <ReactPopup
        contentStyle={this.getContentStyle()}
        overlayStyle={this.state.width >= 699 ? overlayStyle : overlayStyleMobile}
        closeOnDocumentClick
        open={open}
        onClick={closeModal}>
        <div>
          <PopUpContent>
            <MailchimpSubscribePopup url={url}/>
          </PopUpContent>
          <Close src={CloseIcon} onClick={closeModal}/>
        </div>
      </ReactPopup>
    );
  }
}

export default NewsletterPopup;
