import React from 'react';
import styled from 'styled-components';
import fonts from '../../../styles/fonts';
import { Link } from 'gatsby';
import colors from '../../../styles/colors';


const HomeFeatureContainer = styled.div`
    width: 100%;
    height: 100%;
    padding-left: 0px;
    padding-right: 0px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 45px;
    @media(min-width: 992px) {
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 0px;
    }
    @media(min-width: 768px) {
        padding-bottom: 0px;
    }
`;

const FeatureIconContainer = styled.div`
    height: 96px;
    width: 96px;
    border: 1px solid #C2C2C2;
    border-radius: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: ${props => props.white && `${colors.white}`};
`;

const FeatureIcon = styled.img`
    width: 43px;
    height: auto;
`;

const FeatureTitle = styled.div`
    color: #555555;
    ${fonts.MontserratBold};
    font-size: 15px;
    line-height: 30px;
    letter-spacing: 3px;
    text-transform: uppercase;
    padding-top: 20px;
    padding-bottom: 15px;
    text-align: center;
    @media(min-width: 768px) {
        padding-top: 32.5px;
    }
`;

const FeatureDescription = styled.div`
    color: #555555;
    ${fonts.MontserratRegular};
    font-size: 13px;
    line-height: 23px;
    text-align: center;
    width: 100%;
    max-width: 250px;
`;

const FindOutMoreLink = styled(Link)`
    cursor: pointer;
    outline: none;
    border: none;
    background: transparent;
    text-decoration: none;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
        border: none;
    }  
    width: 100%;
    @media(min-width: 768px) {
        width: 168px;
    }
`;

const FindOutMoreButton = styled.div`
    height: 44px;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    outline: none;
    border: 1px solid ${colors.green};
    background: white;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: ${colors.grey};
    ${fonts.MontserratMedium};
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    -webkit-tap-highlight-color: transparent;
    @media(min-width: 768px) {
        width: 168px;
    }
    transition: 0.5s;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
        background: ${colors.green};
        color: ${colors.white};
    }  
`;

const FeatureNumber = styled.div`
    font-size: 45px;
    line-height: 69.46px;
    color: ${colors.green};
    ${fonts.CormorantInfantRegular};
`;


const HomeFeature = (props) => {
    const {icon, title, features, link, index} = props;
    let number;
    if (typeof index !== 'undefined') {
        number = index + 1;
    }
    
    return (
        <HomeFeatureContainer>
            <FeatureIconContainer white={number}>
                {icon && <FeatureIcon src={icon} />}
                {number && <FeatureNumber>{number}</FeatureNumber>}
            </FeatureIconContainer>
            <FeatureTitle>- {title} -</FeatureTitle>
            {features && features.map((feature, index) => <FeatureDescription key={index}>{feature}</FeatureDescription>)}
            {link &&  <FindOutMoreLink to={link}><FindOutMoreButton>Find out more</FindOutMoreButton></FindOutMoreLink>}
        </HomeFeatureContainer>
    )
}

export default HomeFeature;
