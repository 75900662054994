import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import CormorantGaramondBoldWoff from '../resources/fonts/cormorantgaramond-bold-webfont.woff';
import CormorantGaramondBoldWoff2 from '../resources/fonts/cormorantgaramond-bold-webfont.woff2';
import CormorantGaramondItalicWoff from '../resources/fonts/cormorantgaramond-italic-webfont.woff';
import CormorantGaramondItalicWoff2 from '../resources/fonts/cormorantgaramond-italic-webfont.woff2';
import CormorantGaramondLightWoff from '../resources/fonts/cormorantgaramond-light-webfont.woff';
import CormorantGaramondLightWoff2 from '../resources/fonts/cormorantgaramond-light-webfont.woff2';
import CormorantGaramondLightItalicWoff from '../resources/fonts/cormorantgaramond-lightitalic-webfont.woff';
import CormorantGaramondLightItalicWoff2 from '../resources/fonts/cormorantgaramond-lightitalic-webfont.woff2';
import CormorantInfantRegularWoff from '../resources/fonts/cormorantinfant-regular-webfont.woff';
import CormorantInfantRegularWoff2 from '../resources/fonts/cormorantinfant-regular-webfont.woff2';
import MontserratBoldWoff from '../resources/fonts/montserrat-bold-webfont.woff';
import MontserratBoldWoff2 from '../resources/fonts/montserrat-bold-webfont.woff2';
import MontserratLightWoff from '../resources/fonts/montserrat-light-webfont.woff';
import MontserratLightWoff2 from '../resources/fonts/montserrat-light-webfont.woff2';
import MontserratMediumWoff from '../resources/fonts/montserrat-medium-webfont.woff';
import MontserratMediumWoff2 from '../resources/fonts/montserrat-medium-webfont.woff2';
import MontserratRegularWoff from '../resources/fonts/montserrat-regular-webfont.woff';
import MontserratRegularWoff2 from '../resources/fonts/montserrat-regular-webfont.woff2';
import MontserratMediumItalicWoff from '../resources/fonts/montserrat-mediumitalic-webfont.woff';
import MontserratMediumItalicWoff2 from '../resources/fonts/montserrat-mediumitalic-webfont.woff2';



const fontFace = (fontFamily, woff, woff2) => {
    return `
      @font-face {
          font-family: '${fontFamily}';
          src: url(${woff2}) format('woff2'),
          url(${woff}) format('woff');
          font-weight: normal;
          font-style: normal;
      }
    `;
}



const GlobalStyle = createGlobalStyle`
    ${reset}
    ${fontFace('CormorantGaramondBold', CormorantGaramondBoldWoff, CormorantGaramondBoldWoff2)}
    ${fontFace('CormorantGaramondItalic', CormorantGaramondItalicWoff, CormorantGaramondItalicWoff2)}
    ${fontFace('CormorantGaramondLight', CormorantGaramondLightWoff, CormorantGaramondLightWoff2)}
    ${fontFace('CormorantGaramondLightItalic', CormorantGaramondLightItalicWoff, CormorantGaramondLightItalicWoff2)}
    ${fontFace('CormorantInfantRegular', CormorantInfantRegularWoff, CormorantInfantRegularWoff2)}
    ${fontFace('MontserratBold', MontserratBoldWoff, MontserratBoldWoff2)}
    ${fontFace('MontserratLight', MontserratLightWoff, MontserratLightWoff2)}
    ${fontFace('MontserratMedium', MontserratMediumWoff, MontserratMediumWoff2)}
    ${fontFace('MontserratRegular', MontserratRegularWoff, MontserratRegularWoff2)}
    ${fontFace('MontserratMediumItalic', MontserratMediumItalicWoff, MontserratMediumItalicWoff2)}

`;

export default GlobalStyle;
