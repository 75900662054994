const fonts = {
    CormorantGaramondBold: "font-family: 'CormorantGaramondBold', Arial, sans-serif;",
    CormorantGaramondItalic: "font-family: 'CormorantGaramondItalic', Arial, sans-serif;",
    CormorantGaramondLight: "font-family: 'CormorantGaramondLight', Arial, sans-serif;",
    CormorantGaramondLightItalic: "font-family: 'CormorantGaramondLightItalic', Arial, sans-serif;",
    CormorantInfantRegular: "font-family: 'CormorantInfantRegular', Arial, sans-serif;",
    MontserratBold: "font-family: 'MontserratBold', Arial, sans-serif;",
    MontserratLight: "font-family: 'MontserratLight', Arial, sans-serif;",
    MontserratMedium: "font-family: 'MontserratMedium', Arial, sans-serif;",
    MontserratRegular: "font-family: 'MontserratRegular', Arial, sans-serif;",
    MontserratMediumItalic: "font-family: 'MontserratMediumItalic', Arial, sans-serif;",
}

export default fonts;