import React from 'react';
import styled from 'styled-components';
import FacebookIcon from '../../../resources/img/icons/facebook.svg';
import FacebookHoverIcon from '../../../resources/img/icons/facebook-hover.svg';
import LinkedInIcon from '../../../resources/img/icons/linkedin.svg';
import LinkedInHoverIcon from '../../../resources/img/icons/linkedin-hover.svg';
// import TwitterIcon from '../../../resources/img/icons/twitter.svg';
// import TwitterHoverIcon from '../../../resources/img/icons/twitter-hover.svg';
import InstagramIcon from '../../../resources/img/icons/instagram.svg';
import InstagramHoverIcon from '../../../resources/img/icons/instagram-hover.svg';
// import PinterestIcon from '../../../resources/img/icons/pinterest.svg';
// import PinterestHoverIcon from '../../../resources/img/icons/pinterest-hover.svg';

const SocialLinksContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const SocialLink = styled.a`
    cursor: pointer;
    outline: none;
    border: none;
    background: transparent;
    text-decoration: none;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
        border: none;
    }  
`;

const SocialIcon = styled.div`
    background: transparent url(${props => props.background}) no-repeat;
    height: 17px;
    width: 22px;
    background-size: contain;
    margin-left: 5px;
    margin-right: 5px;
    background-position-x: center;
    background-position-y: center;
    &:hover,
    &:active,
    &:focus {
        background: transparent url(${props => props.hoverBackground}) no-repeat;
        background-size: contain; 
        background-position-x: center;
        background-position-y: center;
    }  
    @medi(min-width: 768px) {
        height: 14px;
        width: 17px;
    }
`;

const SocialLinks = () => {
    return (
        <SocialLinksContainer>
            <SocialLink href="https://www.facebook.com/GreenChilliProvisioning" target="_blank" rel="noopener noreferrer"><SocialIcon background={FacebookIcon} hoverBackground={FacebookHoverIcon} /></SocialLink>
            <SocialLink href="https://www.linkedin.com/company/greenchilliprovisioning/about" target="_blank" rel="noopener noreferrer"><SocialIcon background={LinkedInIcon} hoverBackground={LinkedInHoverIcon} /></SocialLink>
            <SocialLink href="https://instagram.com/greenchilliprovisioning.live" target="_blank" rel="noopener noreferrer"><SocialIcon background={InstagramIcon} hoverBackground={InstagramHoverIcon} /></SocialLink>
            {/* <SocialLink><SocialIcon background={PinterestIcon} hoverBackground={PinterestHoverIcon} /></SocialLink> */}
            {/* <SocialLink><SocialIcon background={TwitterIcon} hoverBackground={TwitterHoverIcon} /></SocialLink> */}
            <SocialLink></SocialLink>
        </SocialLinksContainer>
    )
};

export default SocialLinks;