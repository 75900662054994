import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import GlobalStyle from '../styles/global'
import Header from './core/components/Header';
import Footer from './core/components/Footer';
import {GridThemeProvider} from 'styled-bootstrap-grid';
import {ThemeProvider} from 'styled-components';
import {BaseCSS} from 'styled-bootstrap-grid';
import styled from 'styled-components';
import NewsletterPopup from './core/components/NewsLetterPopUp';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PageContainer = styled.div`
    width: 100%;
    flex-grow: 1;
    margin-top: 100px;
    @media(min-width: 768px) {
      margin-top: 155px;
    }
`;

const GlobalPageContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

const gridTheme = {
  breakpoints: {
    xl: 1400,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 575
  },
  row: {
    padding: 0
  },
  col: {
    padding: 0
  },
  container: {
    padding: 0,
    maxWidth: {
      xl: 1400,
      lg: 1400,
      md: 1400,
      sm: 540,
      xs: 540,
    },
  },
};

const styledTheme = {
  
};


const Layout = (props) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        provisions: allContentfulProvision(sort: {fields: sortOrder, order: ASC}) {
      edges {
        node {
          id
          slug
          title
          metaDescription
          thumbnail: thumbnail {
            fluid(
              maxWidth: 1920
              quality: 70
            ) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
      }
    `}
    render={data => {
      return (
        <React.Fragment>
           <ThemeProvider theme={styledTheme}>
              <GridThemeProvider gridTheme={gridTheme}>
                <GlobalStyle />
                <BaseCSS/>
                <GlobalPageContainer>
                  <Header provisions={data.provisions.edges}/>
                  <PageContainer>
                    {props.children}
                  </PageContainer>
                  <NewsletterPopup/>
                  <Footer contact={props.contact} provisions={data.provisions.edges}/>
                </GlobalPageContainer>
              </GridThemeProvider>
            </ThemeProvider>
        </React.Fragment>
      );
    }}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;