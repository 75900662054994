import React, { Component } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import ContactForm from './ContactForm';
import colors from '../../../styles/colors';
import fonts from '../../../styles/fonts';
import SocialLinks from './SocialLinks';
import HowToOrder from './HowToOrder';
import ArrowToTop from '../../../resources/img/back-to-top.svg';
import { Link } from 'gatsby';


const FooterContainer = styled.div`
`;

const ColStyled = styled(Col)`
    @media(min-width: 768px) and (max-width: 991px) {
        margin-left: -7.5px;
        margin-right: -7.5px;
        width: calc(100% + 15px);
    }
`;

const RowStyled = styled(Row)`
    max-width: 1440px;
    margin: auto;
    padding-left: ${props => props.mainRow && '30px'};
    padding-right: ${props => props.mainRow && '30px'};
    box-sizing: border-box;
    display: ${props => props.desktop && 'none'};
    @media(min-width: 768px) {
        display: ${props => props.desktop && 'flex'};
        display: ${props => props.mobile && 'none'};
        padding-left: ${props => props.mainRow && '90px'};
        padding-right: ${props => props.mainRow && '90px'};
    }
`;

const FooterBlockTitle = styled.div`
    color: ${colors.grey};
    ${fonts.MontserratBold};
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 2.4px;
    text-transform: uppercase;
`;

const BorderLineDiv = styled.div`
    height: 4px;
    width: 117px;
    background: ${colors.green};
    margin-top: 20px;
    margin-bottom: 25px;
    @media(min-width: 992px) {
        margin-top: 35px;
        margin-bottom: 40px;
    }
`;

const ContactUsTitle = styled.div`
    ${fonts.CormorantGaramondLight};
    color: ${colors.grey};
    font-size: 40px;
    line-height: 48px;
    padding-bottom: 30px;
    @media(min-width: 768px) {
        padding-bottom: 40px;
    }
    @media(min-width: 992px) {
        font-size: 50px;
        line-height: 58px;
   }
`;

const ContactInfoTitle = styled.div`
    ${fonts.MontserratBold};
    color: ${colors.grey};
    font-size: 15px;
    line-height: 30px;
    letter-spacing: 3px;
    text-transform: uppercase;
    display: ${props => props.desktop && 'none'};
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 50px;
    @media(min-width: 768px) {
        display: ${props => props.desktop && 'block'};
        display: ${props => props.mobile && 'none'};
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 0px;
    }
`;

const ContactInfo = styled.div`
    ${fonts.MontserratMedium};
    color: ${colors.grey};
    font-size: 15px;
    line-height: 30px;
    letter-spacing: 0.5px;
    word-break: break-all;
    padding-right: 30px;
    padding-left: 30px;
    box-sizing: border-box;
    @media(min-width: 768px) {
        padding-right: 30px;
        padding-left: 0px;
        // max-width: 241px;
    }
    @media(min-width: 768px) and (max-width: 991px) {
        padding-bottom: ${props => props.extraPadding && '30px'};
    }
    
    a {
      color: ${colors.grey};
    }
`;

const BeigeContainer = styled(Container)`
    background: #F9F9F9;
    width: 100%;
    box-sizing: border-box;
    padding-top: 45px;
    padding-bottom: 30px;
    margin-bottom: ${props => props.extraMargin && '40px'};
    @media(min-width: 768px) {
        // padding-right: 90px;
        padding-right:  ${props => props.extraMargin && '0px'};
        padding-top: 55px;
        padding-bottom: 40px;
    }
`;

const WhiteContainer = styled(Container)`
    // padding-left: 90px;
    // padding-right: 90px;
    box-sizing: border-box;
    padding-top: 45px;
    padding-bottom: 45px;
    box-sizing: border-box;
    position: relative;
    @media(min-width: 768px) {
        padding-top: 55px;
        padding-bottom: 55px;
    }
`;

const BackToTop = styled.div`
    position: absolute;
    cursor: pointer;
    display: block;
    text-decoration: none;
    outline: none;
    border: 0;
    z-index: 50;
    right: 50px;
    text-align: center;
    bottom: ${props => props.isContact === 'true' ? '-1380px': '10px'};
    
    @media(min-width: 768px) {
       bottom: ${props => props.isContact === 'true' ? '-780px': '10px'};
    }
`;

const ArrowUp = styled.div`
    width: 42px;
    height: 42px;
    background: url(${ArrowToTop}) no-repeat;
    background-size: 100%;
    margin: auto;
`;

const Top = styled.div`
    ${fonts.MontserratBold};
    color: ${colors.grey};
    font-size: 10.4px;
    line-height: 24.05px;
    letter-spacing: 0.65px;
    text-transform: uppercase;
    margin-top: 5px;
`;

const FooterLinkCategory = styled.div`
    ${fonts.CormorantGaramondLight};
    color: ${colors.grey};
    font-size: 40px;
    line-height: 48px;
    padding-bottom: 20px;
    padding-top: 30px;
    @media(min-width: 992px) {
        // font-size: 50px;
        // line-height: 58px;
        padding-top: 0px;
    }
`;

const FooterLink = styled(Link)`
    cursor: pointer;
    outline: none;
    border: none;
    background: transparent;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    width: auto;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
        border: none;
    }  
`;

const FooterLinkItem = styled.span`
    cursor: pointer;
    color: ${colors.grey};
    ${fonts.MontserratMedium};
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1.8px;
    // margin-left: 30px;
    margin-bottom: 10px;
    margin-right: auto;
    // border-bottom: 2px solid transparent;
    width: auto;
    display: inline-block;
    padding-bottom: 3px;
    &:hover,
    &:active,
    &:focus {
        color: ${colors.green};
    }
    @media(min-width: 768px) {
        transition: 0.5s;
    }
`;

const FooterLinksContainer = styled.ul`
    display: flex;
    flex-direction: column;

    li {
        position: relative;
        display: flex;
        flex-direction: row;
        &:hover {
            color: ${colors.green} !important;
            div {
                background: ${colors.green};
            }
            a {
                color: ${colors.green} !important;
            }
        }
    }
`;

class  Footer extends Component {
    state = {
        intervalId: 0,
    };

    scrollStep = () => {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - 100);
    };

    scrollToTop = () => {
        let intervalId = setInterval(this.scrollStep.bind(this), 20);
        this.setState(
          {
              intervalId: intervalId
          }
        );
    };


    render () {

        const { contact, provisions } = this.props;
        const { scrollToTop } = this;
        const links = [{categoryName: 'About', categoryLinks: [{name: 'Location', link: 'location'}, {name: 'Meet the team', link: 'team'}, {name: 'Transport', link: 'transport'}]}, {categoryName: 'Our World', categoryLinks: [{name: 'Sustainable products', link: 'sustainable-produce'}, {name: 'Water', link: 'water'}, {name: 'Packaging', link: 'packaging'}]}, {categoryName: 'Provisions', categoryLinks: provisions}, {categoryName: 'Connect', categoryLinks: [{name: 'News', link: 'news'}, {name: 'Contact', link: 'contact'}, {name: 'Photo credits', link: 'photo-credits'}]} ];

        return (
          <FooterContainer>
              <WhiteContainer>
                  <BackToTop onClick={scrollToTop} isContact={contact ? 'true': 'false'}>
                      <ArrowUp/>
                      <Top>Back to top</Top>
                  </BackToTop>
                  <Container>
                      <RowStyled mainRow>
                          <Col>
                              <div>
                                  <FooterBlockTitle>Contact Us</FooterBlockTitle>
                                  <BorderLineDiv />
                              </div>
                          </Col>
                          <Col lg={6}>
                              <ContactUsTitle>Say hello. <br />Reach out. Order.</ContactUsTitle>
                              <Container>
                                  <RowStyled desktop>
                                      <Col xs={6} sm={6} md={12} lg={12} xl={6}>
                                          <ContactInfoTitle desktop>Office</ContactInfoTitle>
                                          <ContactInfo>
                                              + 33 4 83 88 33 70 <br />
                                              <a href="mailto:provisions@greenchilli.eu">provisions@greenchilli.eu</a> <br/>
                                              7 days per week <br/><br/>
                                              <ContactInfoTitle desktop>Director / Sales</ContactInfoTitle>
                                              Antonio: + 33 6 42 64 08 77<br />
                                              <a href="mailto:management@greenchilli.eu">management@greenchilli.eu</a> <br/>
                                              <br/>
                                          </ContactInfo>
                                      </Col>
                                      <Col xs={6} sm={6} md={12} lg={12} xl={6}>
                                          <ContactInfoTitle desktop>Sales</ContactInfoTitle>
                                          <ContactInfo extraPadding>
                                              Gabi:  + 33 6 16 42 75 02 <br />
                                              <a href="mailto:provisions@greenchilli.eu">provisions@greenchilli.eu</a> <br/>
                                              7 days per week <br/><br/>
                                          </ContactInfo>
                                          {/* <ContactInfoTitle desktop>Sales</ContactInfoTitle>
                                          <ContactInfo extraPadding>
                                              Helen:  + 33 6 10 44 08 38<br />
                                              <a href="mailto:helen@greenchilli.eu">helen@greenchilli.eu</a> <br/>
                                              7 days per week <br/>
                                          </ContactInfo> */}
                                          
                                      </Col>
                                  </RowStyled>
                              </Container>
                          </Col>
                          <ColStyled lg={6} >
                              <ContactForm />
                          </ColStyled>
                      </RowStyled>
                  </Container>
                  <Container>
                      <RowStyled mobile>
                          <Col xs={12} sm={12} md={12}>
                              <ContactInfoTitle mobile>Office</ContactInfoTitle>
                              <ContactInfo>
                                  + 33 4 83 88 33 70 <br />
                                  <a href="mailto:provisions@greenchilli.eu">provisions@greenchilli.eu</a> <br/>
                                  7 days per week
                              </ContactInfo>
                          </Col>
                          <Col xs={12} sm={12} md={12}>
                              <ContactInfoTitle mobile>Director / Sales</ContactInfoTitle>
                              <ContactInfo>
                                  Antonio: + 33 6 42 64 08 77 <br />
                                  <a href="mailto:management@greenchilli.eu">management@greenchilli.eu</a> 
                              </ContactInfo>
                          </Col>
                          <Col xs={12} sm={12} md={12}>
                              <ContactInfoTitle>Sales</ContactInfoTitle>
                              <ContactInfo extraPadding>
                                Gabi:  + 33 6 16 42 75 02 <br />
                                <a href="mailto:provisions@greenchilli.eu">provisions@greenchilli.eu</a><br/>
                                7 days per week
                              </ContactInfo>
                          </Col>
                          {/* <Col xs={12} sm={12} md={12}> */}
                              {/* <ContactInfoTitle>Sales</ContactInfoTitle>
                              <ContactInfo extraPadding>
                                  Helen:  + 33 6 10 44 08 38<br />
                                  <a href="mailto:helen@greenchilli.eu">helen@greenchilli.eu</a><br/>
                                  7 days per week
                              </ContactInfo> */}
                          {/* </Col> */}
                          
                      </RowStyled>
                  </Container>
              </WhiteContainer>
              {contact &&
              <BeigeContainer extraMargin>
                  <HowToOrder />
              </BeigeContainer>}
              <BeigeContainer>
                  <Container>
                      <RowStyled mainRow>
                          <Col xs={6} sm={6 }md={6}>
                              <FooterBlockTitle>Site Map</FooterBlockTitle>
                              <BorderLineDiv />
                          </Col>
                          <Col xs={6} sm={6 }md={6}>
                              <SocialLinks />
                          </Col>
                      </RowStyled>
                      <RowStyled mainRow>
                          {links.map((footerLink, index) => {
                                if(footerLink.categoryName !== 'Provisions') {
                                    return (
                                      <Col md={6} lg={3} key={index}>
                                          <FooterLinksContainer>
                                              <FooterLinkCategory> {footerLink.categoryName}</FooterLinkCategory>
                                              {footerLink.categoryLinks.map((categoryLink, index) =>
                                                <li key={index}>
                                                    <FooterLink to={`/${categoryLink.link}`}>
                                                        <FooterLinkItem>{categoryLink.name}</FooterLinkItem>
                                                    </FooterLink>
                                                </li>
                                              )}
                                          </FooterLinksContainer>
                                      </Col>
                                    )
                                }
                                if(footerLink.categoryName === 'Provisions' && footerLink.categoryLinks && footerLink.categoryLinks.length > 0 ) {
                                    return (
                                      <Col md={6} lg={3} key={index}>
                                          <FooterLinksContainer>
                                              <FooterLinkCategory> {footerLink.categoryName}</FooterLinkCategory>
                                              { footerLink.categoryLinks.map((categoryLink, index) =>
                                                <li key={index}>
                                                    <FooterLink to={`/provision/${categoryLink.node.slug}`}>
                                                        <FooterLinkItem>{categoryLink.node.title}</FooterLinkItem>
                                                    </FooterLink>
                                                </li>
                                              )}
                                          </FooterLinksContainer>
                                      </Col>
                                    )
                                }
                            }
                          )}
                      </RowStyled>
                  </Container>
              </BeigeContainer>
          </FooterContainer>

        )
    }
}
export default Footer;
