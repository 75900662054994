import React from 'react';
import styled from 'styled-components';
import colors from '../../../styles/colors';
import fonts from '../../../styles/fonts';

const PrivacyAgreementContainer = styled.div`
    // padding-top: 20px;
    // padding-top: ${props => props.type === 'footer' && '10px'};
    padding: ${props => props.type === 'image' && '30px'};
    padding-top: ${props => props.type === 'image' && '10px'};
    padding-bottom: ${props => props.type === 'image' && '10px'};
    text-align: center;
    margin-bottom: ${props => props.type === 'news' && '15px'};
    @media(min-width: 768px) {
        padding: ${props => props.type === 'image' && '0px'};
        padding-top: ${props => props.type === 'image' && '5px'};
        padding-left: ${props => props.type === 'image' && '5px'};
        padding-top: ${props => props.type === 'footer' && '0px'};
        max-width: ${props => props.type === 'footer' && '440px'};
        margin: ${props => props.type === 'footer' && 'auto'};
    }
`;

const PrivacyAgreementText = styled.div`
    ${fonts.MontserratRegular};
    font-size: 11px;
    line-height: 15px;
    color: ${colors.grey};
    @media(min-width: 768px) {
        color: ${props => props.type === 'image' && `${colors.white}`};
    }
    a {
        text-decoration: underline;
        color: ${colors.grey};
        ${fonts.MontserratRegular};
        font-size: 11px;
        cursor: pointer;
        @media(min-width: 768px) {
            color: ${props => props.type === 'image' && `${colors.white}`};
        }
    }
`;


const TermsAndPrivacyAgreement = (props) => {
    const {type} = props;
    return (
        <PrivacyAgreementContainer type={type}>
            <PrivacyAgreementText type={type}>By signing up, you agree to receive emails from Green Chilli and accept our <a href="/terms-and-conditions">terms and conditions</a> and <a href="/privacy-policy">privacy policy</a>.</PrivacyAgreementText>
        </PrivacyAgreementContainer>
    )
}

export default TermsAndPrivacyAgreement;