import React, { Component } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import fonts from '../../../styles/fonts';
import colors from '../../../styles/colors';
import TermsAndPrivacyAgreement from './TermsAndPrivacyAgreement';


const ColStyled = styled(Col)`
    box-sizing: border-box;
    display: ${props => props.button && 'flex'};
    flex-direction: ${props => props.button && 'column'};
    align-items: ${props => props.button && 'center'};
    justify-content: ${props => props.button && 'center'};
    padding-bottom: 10px;
        @media(min-width: 768px) {
            padding-bottom: 15px;
            padding-left: 7.5px;
            padding-right: 7.5px;
        }
`;

const FormBackground = styled.div`
`;

const StyledButton = styled.button`
    cursor: pointer;
    background-color: ${colors.white};
    border: 1px solid ${colors.green};
    box-sizing: border-box;
    ${fonts.MontserratMedium};
    height: 45px;
    width: 100%;
    text-decoration: none;
    color: ${colors.grey};
    font-size: 12px;
    line-height: 15px;
    letter-saocing: 1.8px;
    text-transform: uppercase;
    transition-duration: 0.5s;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
    }
    &:hover {
        color: ${colors.white};
        background-color: ${colors.green};
      }
    :disabled {
        cursor: default;
        color: ${colors.white};
        background-color: ${colors.green};
        &:hover {
            color: ${colors.white};
            background-color: ${colors.green};
          }

    }
    @media(min-width: 768px) {
        width: 117px;
    }
`;

const StyledInput = styled.input`
    background-color: transparent !important;    
    ${fonts.MontserratMedium};
    border-radius: 0;
    border: 1px solid ${colors.borderGrey};
    color: ${colors.grey};
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1.8px;
    padding-left: 15px;
    width: 100%;
    height: 46px;
    box-sizing: border-box;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
         ${fonts.MontserratMedium};
         color: ${colors.grey};
         font-size: 12px;
         line-height: 15px;
         letter-spacing: 1.8px;
        //  padding-left: 15px;
      }
      ::-moz-placeholder { /* Firefox 19+ */
        ${fonts.MontserratMedium};
        color: ${colors.grey};
        font-size: 12px;
        line-height: 46px;
        letter-spacing: 1.8px;
        // padding-left: 15px;
      }
      :-ms-input-placeholder { /* IE 10+ */
        ${fonts.MontserratMedium};
        color: ${colors.grey};
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 1.8px;
        // padding-left: 15px;
      }
      :-moz-placeholder { /* Firefox 18- */
        ${fonts.MontserratMedium};
        color: ${colors.grey};
        font-size: 12px;
        line-height: 46px;
        letter-spacing: 1.8px;
        // padding-left: 15px;
      }
      :-webkit-autofill,
      :-webkit-autofill:hover, 
      :-webkit-autofill:focus, 
      :-webkit-autofill:active  {
        -webkit-box-shadow: 0 0 0 24px ${colors.white} inset !important;
      }
      :-webkit-autofill {
        -webkit-text-fill-color: ${colors.grey} !important;
        ${fonts.MontserratMedium};
        color: ${colors.grey};
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 1.8px;
        padding-left: 15px;
      }
    }
`;

const StyledTextArea = styled.textarea`
    background-color: transparent !important; 
    ${fonts.MontserratMedium};
    border-radius: 0;
    border: 1px solid ${colors.borderGrey};
    box-sizing: border-box;
    color: ${colors.grey};
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1.8px;
    padding: 15px;
    width: 100%;
    height: 104px;
    resize: none;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
         ${fonts.MontserratMedium};
         color: ${colors.grey};
        font-size: 12px;
        line-height: 15px;
        letter-sapcing: 1.8px;
    }
    ::-moz-placeholder { /* Firefox 19+ */
        ${fonts.MontserratMedium};
        color: ${colors.grey};
       font-size: 12px;
       line-height: 15px;
       letter-sapcing: 1.8px;
    }
    :-ms-input-placeholder { /* IE 10+ */
        ${fonts.MontserratMedium};
        color: ${colors.grey};
       font-size: 12px;
       line-height: 15px;
       letter-sapcing: 1.8px;
    }
    :-moz-placeholder { /* Firefox 18- */
        ${fonts.MontserratMedium};
        color: ${colors.grey};
       font-size: 12px;
       line-height: 15px;
       letter-sapcing: 1.8px;
    }
`;

// const SuccessMessage = styled.div`
//     padding-bottom: 25px;
//     @media(min-width: ${theme.breakpoints.medium}) {
//         padding-bottom: 0px;
//         padding-right: 10px;
//     }
// `;

// const ThankYouMessage = styled.div`
//     ${fonts.selfModern};
//     color: ${colors.greyDark};
//     font-size: 24px;
//     line-height: 30px;
//     padding-bottom: 3px;
// `;

// const ContactMessage = styled.div`
//     ${fonts.faktNormal};
//     color: ${colors.grey};
//     font-size: 14px;
//     line-height: 22px;
// `;

const initialState = {
    name: '',
    email: '',
    telephone: '',
    position: '',
    message: '',
    formSubmitted: false,
    success: false
};

class ContactForm extends Component {
    state = initialState;

    handleChange = (event) => {
        let target = event.target;

        let stateObject = {};
        stateObject[target.name] = target.value;

        this.setState(stateObject);
    }

    handleSubmit = (event) => {
        event.preventDefault();

        let contactForm = document.getElementById('contact-form');
        let formData = new FormData(contactForm);

        fetch(contactForm.getAttribute('action'), {
            method: 'POST',
            body: formData
        }).then((response) => response)
            .then((response) => {
                if (response.status === 200) {
                    this.setState(initialState);

                    this.setState({
                        formSubmitted: true,
                        success: true
                    });
                } else {
                    this.setState({
                        formSubmitted: true,
                        success: false
                    });
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    render() {
        const { handleChange, handleSubmit } = this;
        const { name, position, email, message, telephone,  success } = this.state;
        // formSubmitted,
        return (
            <FormBackground>
                <form id="contact-form" method="POST" name="contact"
                    data-netlify="true" data-netlify-honeypot="bee"
                    onSubmit={handleSubmit}>
                    <Container>
                        <Row>
                            <ColStyled md={6}>
                                <StyledInput
                                    id="name"
                                    type="text"
                                    name="name"
                                    value={name}
                                    onChange={handleChange}
                                    required
                                    placeholder="Name" />
                            </ColStyled>
                            <ColStyled md={6}>
                                <StyledInput
                                    id="email"
                                    type="email"
                                    name="email"
                                    value={email}
                                    onChange={handleChange}
                                    required
                                    placeholder="Email Address" />
                                <div style={{ display: 'none' }}>
                                    <input name="bee" />
                                    <input type="hidden" name="form-name" value="contact" />
                                </div>
                            </ColStyled>
                            <ColStyled md={6}>
                                <StyledInput
                                    id="telephone"
                                    type="tel"
                                    name="telephone"
                                    value={telephone}
                                    onChange={handleChange}
                                    placeholder="Telephone" />
                            </ColStyled>
                            <ColStyled md={6}>
                                <StyledInput
                                    id="position"
                                    type="text"
                                    name="position"
                                    value={position}
                                    onChange={handleChange}
                                    required
                                    placeholder="Position" />
                            </ColStyled>
                            <ColStyled >
                                <StyledTextArea
                                    id="message"
                                    name="message"
                                    value={message}
                                    onChange={handleChange}
                                    placeholder="Message" />
                            </ColStyled>
                            <ColStyled button>
                                {/* <div> */}
                                {/* {success === true &&
                                        <SuccessMessage>
                                            <ThankYouMessage>Thank you for your enquiry.</ThankYouMessage>
                                            <ContactMessage>Someone from our team will be back to you shortly.</ContactMessage>
                                        </SuccessMessage>} */}
                                {/* </div> */}
                                <StyledButton type="submit" aria-label="Submit" disabled={success === true ? true : false}>
                                    {success === true ? 'Thank you!' : 'Send'}
                                </StyledButton>
                            </ColStyled>
                        </Row>
                        <TermsAndPrivacyAgreement type="footer"/>
                    </Container>
                </form>

            </FormBackground>
        );
    }

}

export default ContactForm;