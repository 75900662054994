import React, { Component } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import LogoImg from '../../../resources/img/logo.svg';
import SocialLinks from './SocialLinks';
import { Link } from 'gatsby';
import fonts from '../../../styles/fonts';
import BurgerIcon from '../../../resources/img/home/burger-menu.svg';
import CloseIcon from '../../../resources/img/home/close-menu.svg';
import colors from '../../../styles/colors';
import AccordionClose from '../../../resources/img/home/dropdown-close.svg';
import AccordionOpen from '../../../resources/img/home/dropdown-open.svg';


const FixedHeader = styled.div`
    position: fixed;
    z-index: 111;
    width: 100%;
`;
const HeaderContainer = styled(Container)`
    width: 100%;
    height: 100px;
    background: white;
    position: relative;
    @media(min-width: 992px) {
        height: 155px;
    }
`;

const RowStyled = styled(Row)`
    margin: auto;
    height: 100%;
`;

const LogoContainer = styled.div`
    height: 100%;
    max-width: 60px;
    margin-left: 30px;
    @media(min-width: 992px) {
        max-width: 126px;
        width: auto;
        margin-left: 100px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
`;

const Logo = styled.img`
    height: 100%;
    width: 100%;
    object-fit: contain;
`;

const MenuRightSideContainer = styled.div`
    display: none;
    @media(min-width: 992px) {
        width: 100%;
        height: 100%;
        padding-top: 25px;
        padding-bottom: 22px;
        padding-right: 60px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
    }

`;

const MenuLinks = styled.div`
    // width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

const MenuLink = styled(Link)`
    cursor: pointer;
    outline: none;
    border: none;
    background: transparent;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
        border: none;
    }  
    &.active {
        span {
            text-decoration: none;
            outline: none;
            box-shadow: none;
            border-color: ${colors.green};
        }
    }
`;

const StyledLink = styled(Link)`
    cursor: pointer;
    outline: none;
    border: none;
    background: transparent;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
        border: none;
    }  
`;

const MenuItem = styled.span`
    cursor: pointer;
    color: #555555;
    ${fonts.MontserratMedium};
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1.8px;
    margin-left: 20px;
    margin-right: 20px;
    border-bottom: 2px solid transparent;
    -webkit-tap-highlight-color: transparent;
    padding-bottom: 3px;
    @media(min-width: 768px) {
        transition: 0.5s;
    }
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      outline: none;
      box-shadow: none;
      border-color: ${colors.green};
    }
`;

const LoginButton = styled.button`
    cursor: pointer;
    outline: none;
    border: none;
    background: transparent;
    text-decoration: none;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
        border: none;
    }  
`;

const Login = styled.div`
    color: #ADE100;
    ${fonts.MontserratBold};
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 1.65px;
    text-transform: uppercase;
    margin-left: 90px;
`;

const ContactContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

const OrderButton = styled.button`
    margin-left: 90px;
    height: 44px;
    width: 168px;
    box-sizing: border-box;
    cursor: pointer;
    outline: none;
    border: 1px solid ${colors.green};
    background: transparent;
    color: ${colors.grey};
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
        background: ${colors.green};
        color: ${colors.white};
    }  
`;

const Order = styled.div`
    ${fonts.MontserratMedium};
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1.8px;
    text-transform: uppercase;
`;


const BurgerMenuContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    @media(min-width: 992px) {
        display: none;
    }
`;

const BurgerMenu = styled.img`
    width: 35px;
    height: auto;
    margin-right: 30px;
    max-height: 22px;

`;

const ParentMenuItem = styled.div`
  position: relative;
`;

const StyledList = styled.ul`
    display: ${props => props.visible === false && 'none'};
    position: absolute;
    left: 45px;
    top: 60px;
    width: 100%;
    z-index: 11;
    @media(min-width: 768px) {
        transition: 0.5s;
    }
    li {
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 1.8px;
        ${fonts.MontserratMedium};
        color: ${colors.grey};
        margin-bottom: 15px;
        white-space: nowrap;
        position: relative;
        cursor: pointer;
        @media(min-width: 768px) {
            transition: 0.5s;
        }
        &:hover {
            color: ${colors.green} !important;
            div {
                background: ${colors.green};
            }
            a {
                color: ${colors.green} !important;
            }
            span {
                color: ${colors.green} !important;
            }
        }
    }
`;

const LinkStyled = styled(Link)`
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1.8px;
    ${fonts.MontserratMedium};
    color: ${colors.grey};
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    @media(min-width: 768px) {
        transition: 0.5s;
    }
`;

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 150px;
  height: ${props => props.provisions === true && '370px'};
  background: white;
  opacity: 0.9;
  top: 155px;
  left: 0;
  right: 0;
  z-index: 10;
  display: ${props => props.visible === false && 'none'};
  @media(max-width: 992px) {
      display: none;
  }
`;

const MobileNavContainer = styled.div`
    position: fixed;
    width: 100%;
    // height: calc(100vh - 100px);
    background: ${colors.white};
    // opacity: 0.9;
    top: 100px;
    left: 0px;
    display: ${props => props.visible === false && 'none'};
    z-index: 11111;
    padding: 30px;
    padding-top: 50px;
    padding-bottom: 50px;
    box-sizing: border-box;
    @media(min-width: 992px) {
        display: none;
    }
`;
const MobileNavLinks = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ListLine = styled.div`
    height: 2px;
    width: 15px;
    margin-right: 10px;
    background: transparent;
    position: absolute;
    left: -25px;
    top: calc(50% - 1px);
    @media(min-width: 768px) {
        transition: 0.5s;
    }
`;


const MobileNavItem = styled.div`
    ${fonts.CormorantGaramondLight};
    color: ${colors.grey};
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 20px;
    -webkit-tap-highlight-color: transparent;
    border-bottom: 3px solid transparent;
    // transition: 0.2s;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
        border-color: ${colors.green};
    }  
`;



const AccordionImgStyled = styled.img`
    width: 15px;
    height: auto;
    position: absolute;
    right: -35px;
    top: calc(50% - 10px);
`;

const DropdownRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const MobileSubLinks = styled.ul`
    margin-bottom: 30px;
    margin-top: 15px;
    li {
        margin-bottom: 10px;
        text-align: center;
        position: relative;
        // transition: 0.2s;
        &:hover {
            color: ${colors.green} !important;
            div {
                background: ${colors.green};
            }
            a {
                color: ${colors.green} !important;
            }
        }
    }
`;

const SocialLinksContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 50px;
`;

const MobileOverlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100vh;
    background: white;
    opacity: 0.9;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    display: ${props => props.visible === false && 'none'};
    @media(min-width: 992px) {
        display: none;
    }
`;


class Header extends Component {
    state={
        overlayVisible: false,
        subLinksVisible: false,
        category: '',
        mobileNavOpen: false,
        isCollapsed: true,
    }

    toggleOverlay = (value, name) => this.setState({overlayVisible: value, subLinksVisible: value, category: name});

    toggleMobileNav = () => this.setState({mobileNavOpen: !this.state.mobileNavOpen});

    toggleAccrodion = (name) => this.setState({isCollapsed: !this.state.isCollapsed, category: name});
      

    render() {
        const { toggleOverlay, toggleMobileNav } = this;
        const { overlayVisible, subLinksVisible, category, mobileNavOpen, isCollapsed } = this.state;
        const {provisions} = this.props;
        const links = [{name: 'About', sublinks: [{name: 'Location', link: '/location'}, {name: 'Meet The Team', link: '/team'}, {name: 'Transport', link: '/transport'}]}, {name: 'Our World', sublinks: [{name: 'Sustainable Products', link: '/sustainable-produce'}, {name: 'Water', link: '/water'}, {name: 'Packaging', link: '/packaging'}]}, {name: 'Provisions', sublinks: provisions}, {name: 'News', link: '/news'}, {name: 'Contact', link: '/contact'}];

        return (
            <FixedHeader>
            <HeaderContainer>
                    <RowStyled>
                        <Col xs={4} sm={4} md={4} lg={2}>
                            <MenuLink to={'/'}>
                                <LogoContainer>
                                    <Logo src={LogoImg} />
                                </LogoContainer>
                            </MenuLink>
                        </Col>
                        <Col xs={8} sm={8} md={8} lg={10}>
                            <BurgerMenuContainer onClick={() => toggleMobileNav()}>
                                <BurgerMenu src={mobileNavOpen? CloseIcon : BurgerIcon}/>
                            </BurgerMenuContainer>
                            <MenuRightSideContainer>
                            <ContactContainer>
                                <SocialLinks />
                                <StyledLink to={'/coming-soon'}>
                                    <OrderButton>
                                        <Order>Estimate / Order</Order>
                                    </OrderButton>
                                </StyledLink>
                            </ContactContainer>
                            <MenuLinks  >
                                {links.map((menuLink, index) => {
                                    if(menuLink['sublinks']) {
                                        if(menuLink.name === 'Provisions' && menuLink.sublinks && menuLink.sublinks.length > 0 ) {
                                            return (
                                                <ParentMenuItem onMouseEnter={()=> {toggleOverlay(true, menuLink.name)}} key={index}>
                                                   <MenuLink to={'/provisions'} activeClassName={'active'}><MenuItem>{menuLink.name}</MenuItem></MenuLink>
                                                    <StyledList visible={subLinksVisible && category === menuLink.name}>
                                                        <li key={index}>
                                                                    <ListLine />
                                                                    <LinkStyled to={'/provisions'} onClick={()=> {toggleOverlay(false, '')}}>
                                                                        <span>All Provisions</span>
                                                                    </LinkStyled>
                                                                </li>
                                                        {menuLink.sublinks.map((subLink, index) => 
                                                            <li key={index}>
                                                                <ListLine />
                                                                <LinkStyled to={`/provision/${subLink.node.slug}`} onClick={()=> {toggleOverlay(false, '')}}>
                                                                    <span>{subLink.node.title}</span>
                                                                </LinkStyled>  
                                                            </li>
                                                        )}
                                                    </StyledList>
                                                </ParentMenuItem>
                                            )
                                        }
                                        if(menuLink.name !== 'Provisions') {
                                            return (
                                                <ParentMenuItem onMouseEnter={()=> {toggleOverlay(true, menuLink.name)}} key={index}>
                                                   <MenuItem>{menuLink.name}</MenuItem>
                                                    <StyledList visible={subLinksVisible && category === menuLink.name}>
                                                        {menuLink.sublinks.map((subLink, index) => 
                                                            <li key={index}>
                                                                <ListLine />
                                                                <LinkStyled to={subLink.link} onClick={()=> {toggleOverlay(false, '')}}>
                                                                    <span>{subLink.name}</span>
                                                                </LinkStyled>
                                                            </li>
                                                        )}
                                                    </StyledList>
                                                </ParentMenuItem>
                                            )
                                        }
                                    }
                                    if(menuLink['link']) {
                                        return (
                                            <MenuLink to={menuLink.link} key={index} activeClassName={'active'} onMouseEnter={()=> {toggleOverlay(false, '')}}><MenuItem>{menuLink.name}</MenuItem></MenuLink>
                                        )
                                    }
                                })}
                                 <StyledLink to={'/coming-soon'}>
                                    <LoginButton>
                                        <Login>Login / Sign up</Login>
                                    </LoginButton>
                                 </StyledLink>
                            </MenuLinks>
                            </MenuRightSideContainer>
                        </Col>
                    </RowStyled>
                    <Overlay visible={overlayVisible} onMouseLeave={() => {toggleOverlay(false)}} provisions={category === 'Provisions'}/>
                    <MobileNavContainer visible={mobileNavOpen}>
                        <MobileNavLinks>
                            {links.map((menuLink, index) => {
                                            if(menuLink['sublinks']) {
                                                if(menuLink.name === 'Provisions' && menuLink.sublinks && menuLink.sublinks.length > 0 ) {
                                                    return (
                                                        <div key={index}>
                                                        <DropdownRow onClick={() => this.toggleAccrodion(menuLink.name)}>
                                                            <div style={{position: 'relative'}}>
                                                                <MobileNavItem> {menuLink.name} </MobileNavItem>
                                                                    <AccordionImgStyled src={(!isCollapsed && category === menuLink.name) ? AccordionClose : AccordionOpen }
                                                                                            alt="Accordion Image"/>
                                                            </div>
                                                        </DropdownRow>
                                                            {!isCollapsed && category === menuLink.name &&
                                                                <MobileSubLinks>
                                                                     <li key={index}>
                                                                         
                                                                            <LinkStyled to={'/provisions'} onClick={() => toggleMobileNav()}>
                                                                                <span>All Provisions</span>
                                                                            </LinkStyled>
                                                                        </li>
                                                                    {menuLink.sublinks.map((subLink, index) => 
                                                                        <li key={index}>
                                                                         
                                                                            <LinkStyled to={`/provision/${subLink.node.slug}`} onClick={() => toggleMobileNav()}>
                                                                                <span>{subLink.node.title}</span>
                                                                            </LinkStyled>
                                                                        </li>
                                                                    )}
                                                                </MobileSubLinks> }
                                                    </div>
                                                    )
                                                }
                                                if(menuLink.name !== 'Provisions') {
                                                    return (
                                                    <div key={index}>
                                                        <DropdownRow onClick={() => this.toggleAccrodion(menuLink.name)}>
                                                            <div style={{position: 'relative'}}>
                                                                <MobileNavItem> {menuLink.name} </MobileNavItem>
                                                                    <AccordionImgStyled src={(!isCollapsed && category === menuLink.name) ? AccordionClose : AccordionOpen }
                                                                                            alt="Accordion Image"/>
                                                            </div>
                                                        </DropdownRow>
                                                            {!isCollapsed && category === menuLink.name &&
                                                                <MobileSubLinks>
                                                                    {menuLink.sublinks.map((subLink, index) => 
                                                                        <li key={index}>
                                                                         
                                                                            <LinkStyled to={subLink.link} onClick={() => toggleMobileNav()}>
                                                                                <span>{subLink.name}</span>
                                                                            </LinkStyled>
                                                                        </li>
                                                                    )}
                                                                </MobileSubLinks> }
                                                    </div>
                                                    )
                                                }
                                            }
                                            if(menuLink['link']) {
                                                return (
                                                    <MenuLink to={menuLink.link} key={index}><MobileNavItem>{menuLink.name}</MobileNavItem></MenuLink>
                                                )
                                            }
                                        })}
                        </MobileNavLinks>
                        <SocialLinksContainer>
                            <SocialLinks/>
                        </SocialLinksContainer>
                    </MobileNavContainer>
                    <MobileOverlay visible={mobileNavOpen}/>
            </HeaderContainer>
            </FixedHeader>
        )
    }
}

export default Header;
