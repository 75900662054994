import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import fonts from '../../../styles/fonts';
import colors from '../../../styles/colors';
import HomeFeature from '../../home/components/HomeFeature';


const BeigeContainer = styled(Container)`
    background: #F9F9F9;
    width: 100%;
    padding: 30px;
    padding-top: 45px;
    padding-bottom: 0px;
    box-sizing: border-box;
    @media(min-width: 992px) {
        padding-left: 90px;
        padding-right: 90px;
        padding-top: 55px;
        padding-bottom: 55px;
    }
    @media (min-width: 768px) and (max-width: 992px) {
        padding-bottom: 45px;
    }
`;

const BlockTitle = styled.div`
    color: ${colors.grey};
    ${fonts.MontserratBold};
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 2.4px;
    text-transform: uppercase;
`;

const BlockDescription = styled.div`
    color: ${colors.grey};
   ${fonts.CormorantGaramondLight};
   font-size: 40px;
   line-height: 48px;
   max-width: 504px;
   padding-bottom: 30px;
   @media(min-width: 992px) {
        font-size: 50px;
        line-height: 58px;
    }
`;

const BorderLineDiv = styled.div`
    height: 4px;
    width: 117px;
    background: #ADE100;
    margin-top: 20px;
    margin-bottom: 25px;
    @media(min-width: 992px) {
        margin-top: 35px;
        margin-bottom: 40px;
    }
`;

const TitleLine = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    @media(min-width: 768px) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
`;

const RowStyled = styled(Row)`
    height: 100%;
    position: relative;
`;

const ColStyled = styled(Col)`
    height: 100%;
    position: relative;
    z-index: 2;
`;

const DottedLine = styled.div`
   display: none;
    @media(min-width: 768px) {
        display: block;
        width: 70%;
        margin: auto;
        left: 15%;
        position: absolute;
        border: 1px dashed ${colors.green};
        top: 48px;
        z-index: 1;
    }
`;

const HowToOrder = () => {
    const orderSteps = [{ title: 'Create an account', features: ['Login to your account to view our up-to-date products lists and prices']}, {title: 'Create shopping list', features: ['Browse from thousands of carefully selected products from premium suppliers']}, { title: 'Confirm order', features: ['Once your list is submitted we will contact you directly']}]
    return (
        <BeigeContainer>
            <TitleLine>
                <div>
                    <BlockTitle>How to order</BlockTitle>
                    <BorderLineDiv/>
                </div>
                <div>
                    <BlockDescription>We built our technology  to deliver excellence.</BlockDescription>
                </div>
            </TitleLine>
            <Container>
                    <RowStyled>
                        {orderSteps.map((orderStep, index) =>
                            <ColStyled md={4} key={index}><HomeFeature title={orderStep.title} features={orderStep.features} index={index}/></ColStyled>
                        )}
                        <DottedLine />
                    </RowStyled>
                </Container>
        </BeigeContainer>
    )
}

export default HowToOrder;