const colors = {
    green: '#ADE100',
    grey: '#555555',
    lightGrey: '#F9F9F9',
    borderGrey: '#CCCCCC',
    white: '#FFFFFF',
    black: '#000000',
    beige: '#F9F9F9',
    red: '#8B0000',

}

export default colors;